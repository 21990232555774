// src/App.js
import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import About from "./components/About";
import Services from "./components/Services";
import Gallery from "./components/Gallery";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import "./App.css";
import ContactForm from "./components/ContactForm";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import AudioPlayer from "./components/AudioPlayer";

function App() {
  ReactGA.initialize("G-0TV1RE6BGV");

  useEffect(() => {
    document.title = "Cantora - Soulful sounds, straight from the heart";
  }, []);

  return (
    <div className="App">
      <Header />
      <Hero />
      <About />
      <Services />
      <Gallery />
      <AudioPlayer />
      <Testimonials />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
