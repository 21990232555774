import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <section className="hero">
      <div className="container">
        <h1>70s, 80s, 90s, Acoustic, Classic Hits, Pop, Rock</h1>
        <p>Captivating and memorable performances for any occasion.</p>
      </div>
    </section>
  );
};

export default Hero;
