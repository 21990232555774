// src/components/AudioPlayer.js
import React from "react";
import "./AudioPlayer.css";

const AudioPlayer = () => {
  return (
    <section id="music" className="audio-player">
      <h2>Listen to Our Music</h2>
      <audio controls preload="none">
        <source src="/audio/highlights.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </section>
  );
};

export default AudioPlayer;
