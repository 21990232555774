import React from "react";
import "./Services.css";

const Services = () => {
  return (
    <section id="services" className="services">
      <div className="container">
        <h2>Our Services</h2>
        <p>
          Whether you're planning an intimate gathering or a lively celebration,
          we offer music packages to suit every event:
        </p>
        <ul className="service-list">
          <li>
            <strong>Duo:</strong> Intimate and elegant, perfect for creating a
            cozy atmosphere at smaller gatherings.
          </li>
          <li>
            <strong>Trio:</strong> A balanced blend of sounds, ideal for
            versatile events that demand musical finesse.
          </li>
          <li>
            <strong>Full Band:</strong> High-energy performances designed to
            bring larger celebrations to life.
          </li>
        </ul>
        <p className="tailored-packages">
          We also offer tailored packages that can be customized to fit your
          budget and event needs. Let's make your event unforgettable with the
          perfect soundtrack!
        </p>
      </div>
    </section>
  );
};

export default Services;
