import React from "react";
import "./About.css";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <h2>About Us</h2>
        <p>
          From the soft tones of acoustic background music to the energetic
          beats that get the party pumping, Cantora has the perfect sound for
          your event. Our seasoned musicians have the talent to blend genres and
          create unforgettable sonic experiences. Let us tailor our sound to
          your specific vibe and make your event memorable.
        </p>
      </div>
    </section>
  );
};

export default About;
