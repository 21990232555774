import React from "react";
import "./Testimonials.css";

const testimonials = [
  {
    name: "Katie Burroughs",
    feedback:
      "Cantora are the best! We can not thank them enough for the great music and singing on Saturday night at our wedding. They really know how to work the crowd while having heaps of fun.Their vocals and guitar skills and flawless!! We highly recommend Cantora Duo for any event. They are great and easy to deal with which makes planning a breeze. Thanks heaps ",
    role: "Private Client",
  },
  {
    name: "Nichola Roberts",
    feedback:
      "Thank you so much for playing at our Wedding, you guys were amazing to deal with and sounded fantastic.Everyone commented on how wonderful both the afternoon and evening sets were. Thanks very much from Rhett and Nichola😊",
    role: "Private Client",
  },
  // Add more testimonials here
];

const Testimonials = () => {
  return (
    <section id="testimonials" className="testimonials">
      <h2>What Our Clients Say</h2>
      <div className="testimonial-container">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <p className="feedback">"{testimonial.feedback}"</p>
            <p className="name">— {testimonial.name}</p>
            <p className="role">{testimonial.role}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
