import React from "react";
import "./Gallery.css";

const photos = [
  { src: "/gallery/photo1.png", alt: "Photo 1" },
  { src: "/gallery/photo2.png", alt: "Photo 2" },
  { src: "/gallery/photo3.png", alt: "Photo 3" },
  { src: "/gallery/photo4.png", alt: "Photo 4" },
  { src: "/gallery/photo5.png", alt: "Photo 5" },
  { src: "/gallery/photo6.png", alt: "Photo 6" },
];

const Gallery = () => {
  return (
    <section id="gallery" className="gallery">
      <div className="container">
        <h2>Gallery</h2>
        <div className="gallery-wrap">
          {photos.map((photo, index) => (
            <div key={index} className="gallery-item">
              <img
                src={photo.src}
                alt={photo.alt}
                srcSet={`
                  ${photo.src} 300w, 
                  ${photo.src} 600w, 
                  ${photo.src} 900w
                `}
                sizes="(max-width: 600px) 300px, (max-width: 1200px) 600px, 900px"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Gallery;
